import { useCallback, useEffect, useState } from "react";
import WordCloud from "react-d3-cloud";
import { Outlet } from "react-router-dom";
import { gcpWords, getWordData, javaWords, k8sWords, linuxWords, pythonWords, scalaWords } from "./Words";
import { useAuth } from "./AuthProvider";
import { Dropdown } from "react-bootstrap";

export function MainPage() {

    const { token } = useAuth();

    const handleKeyPress = useCallback((event) => {
        // console.log(`Key pressed: ${event.key}`);
        if (event.ctrlKey && event.keyCode === 80) {
            console.log("do print!!")
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    }, []);

    useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);



    // let audio = undefined;
    const [tellMeState, setTellMeState] = useState({
        style: "btn btn-primary btn-xl text-uppercase",
        playing: false,
        audio: undefined,
        text: "Tell me more",
    });

    function playPauseVoice() {
        console.log("playPauseVoice: ", tellMeState);
        if (tellMeState.playing) {
            tellMeState.audio.pause();
            setTellMeState({
                style: "btn btn-primary btn-xl text-uppercase",
                playing: false,
                audio: undefined,
                text: "Tell me more",
            });
        } else {
            const audio = new Audio("/assets/voice.wav")
            audio.play();
            audio.onended = (ev) => {
                setTellMeState({
                    style: "btn btn-primary btn-xl text-uppercase",
                    playing: false,
                    audio: undefined,
                    text: "Tell me more",
                });
            };
            setTellMeState({
                style: "btn btn-primary btn-xl text-uppercase playing",
                playing: true,
                audio: audio,
                text: "Shut up!",
            });
        }
    }

    // const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

    return <div id="page-top">
        <Outlet />
        {/* {!token && (
            <div className="user-float">
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        &nbsp;
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/login">Login</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )}
        {token && (
            <div className="user-float">
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {localStorage.getItem("givenName")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/api-test">API test</Dropdown.Item>
                        <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )} */}
        <header className="masthead">
            <div className="container">
                <div className="masthead-subheading">Welcome!</div>
                <div className="masthead-heading text-uppercase">This is Matti Pehrs</div>
                <span className={tellMeState.style} onClick={playPauseVoice}>{tellMeState.text}</span>
                &nbsp;&nbsp;
                <a className="btn btn-primary btn-xl text-uppercase" href="#contact">Let's talk!</a>
                <div className="top-buttons">
                    <a title="Mattis Linkedin profile" className="btn btn-light btn-social mx-2" target="_" href="https://www.linkedin.com/in/mattipehrs/" aria-label="Matti Pehrs LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                    <a title="Mattis Github repos" className="btn btn-light btn-social mx-2" target="_" href="https://github.com/pehrs?tab=repositories" aria-label="Matti Pehrs Github Profile"><i className="fab fa-github"></i></a>
                    <a title="Mattis CV" className="btn btn-light btn-social mx-2" target="_" href="/assets/cv-matti-pehrs.pdf" aria-label="Matti Pehrs CV"><i className="fas fa-file-pdf"></i></a>
                </div>
            </div>
        </header>

        {/* Services */}

        <section className="page-section" id="services">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Services</h2>
                    <h3 className="section-subheading text-muted">30+ years in the IT industry gives...</h3>
                </div>
                <div className="row text-center">
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x" >
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-book-atlas fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">Experience</h4>
                        <p className="text-muted">
                            I have worked with product development and consulting in Sweden, Canada and the USA.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-computer fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">Passion</h4>
                        <p className="text-muted">
                            I love computers and what we are able to do with them.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-star fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">Quality</h4>
                        <p className="text-muted">
                            Like any craftsman I'm focused on delivering the right quality at the right time.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        {/* Technologies */}

        <section className="page-section page-section-lighter" id="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Technologies</h2>
                    <h3 className="section-subheading text-muted">Some of the things I have picked up.</h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#java">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content glow">
                                        {/* <i className="fas fa-plus fa-3x"></i> */}
                                        <div style={{ width: 416, height: 277 }}>
                                            <WordCloud
                                                width={416}
                                                height={277}
                                                data={getWordData(javaWords)}
                                                font="Dosis"
                                                fontWeight={600}
                                                fontSize={24}
                                                spiral="rectangular"
                                                rotate={(word) => word.value % 360}
                                                padding={5}
                                                random={Math.random}
                                                fill="#ffc800"
                                            ></WordCloud>
                                        </div>
                                    </div>
                                </div>
                                <img className="img-fluid" src="assets/pgm-java.jpg" alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Java</div>
                                <div className="portfolio-caption-subheading text-muted">Since 1995</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#python">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content">
                                        {/* <i className="fas fa-plus fa-3x"></i> */}
                                        <div style={{ width: 416, height: 277 }}>
                                            <WordCloud
                                                width={416}
                                                height={277}
                                                data={getWordData(pythonWords)}
                                                font="Dosis"
                                                fontWeight="bold"
                                                fontSize={10}
                                                spiral="rectangular"
                                                rotate={(word) => word.value % 360}
                                                padding={5}
                                                random={Math.random}
                                                fill="#ffc800"
                                            ></WordCloud>
                                        </div>
                                    </div>
                                </div>
                                <img id="p2" className="img-fluid" src="assets/pgm-python.jpg" alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Python</div>
                                <div className="portfolio-caption-subheading text-muted">Since 2013</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#scala">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content">
                                        {/* <i className="fas fa-plus fa-3x"></i> */}
                                        <div style={{ width: 416, height: 277 }}>
                                            <WordCloud
                                                width={416}
                                                height={277}
                                                data={getWordData(scalaWords)}
                                                font="Dosis"
                                                fontWeight="bold"
                                                fontSize={24}
                                                spiral="rectangular"
                                                rotate={(word) => word.value % 360}
                                                padding={5}
                                                random={Math.random}
                                                fill="#ffc800"
                                            ></WordCloud>
                                        </div>
                                    </div>
                                </div>
                                <img className="img-fluid" src="assets/pgm-scala.jpg" alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Scala</div>
                                <div className="portfolio-caption-subheading text-muted">Since 2011</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#linux">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content">
                                        {/* <i className="fas fa-plus fa-3x"></i> */}
                                        <div style={{ width: 416, height: 277 }}>
                                            <WordCloud
                                                width={416}
                                                height={277}
                                                data={getWordData(linuxWords)}
                                                font="Dosis"
                                                fontWeight="bold"
                                                fontSize={24}
                                                spiral="rectangular"
                                                rotate={(word) => word.value % 360}
                                                padding={5}
                                                random={Math.random}
                                                fill="#ffc800"
                                            ></WordCloud>
                                        </div>
                                    </div>
                                </div>
                                <img className="img-fluid" src="assets/data-center-linux.jpg" alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Linux</div>
                                <div className="portfolio-caption-subheading text-muted">Since 1999</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4 mb-sm-0">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#k8s">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content">
                                        {/* <i className="fas fa-plus fa-3x"></i> */}
                                        <div style={{ width: 416, height: 277 }}>
                                            <WordCloud
                                                width={416}
                                                height={277}
                                                data={getWordData(k8sWords)}
                                                font="Dosis"
                                                fontWeight="bold"
                                                fontSize={24}
                                                spiral="rectangular"
                                                rotate={(word) => word.value % 360}
                                                padding={5}
                                                random={Math.random}
                                                fill="#ffc800"
                                            ></WordCloud>
                                        </div>
                                    </div>
                                </div>
                                <img className="img-fluid" src="assets/data-center-k8s.jpg" alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Kubernetes</div>
                                <div className="portfolio-caption-subheading text-muted">Since 2018</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#gcp">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content">
                                        {/* <i className="fas fa-plus fa-3x"></i> */}
                                        <div style={{ width: 416, height: 277 }}>
                                            <WordCloud
                                                width={416}
                                                height={277}
                                                data={getWordData(gcpWords)}
                                                font="Dosis"
                                                fontWeight="bold"
                                                fontSize={24}
                                                spiral="rectangular"
                                                rotate={(word) => word.value % 360}
                                                padding={5}
                                                random={Math.random}
                                                fill="#ffc800"
                                            ></WordCloud>
                                        </div>
                                    </div>
                                </div>
                                <img className="img-fluid" src="assets/data-center-gcp.jpg" alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Google Cloud Platform</div>
                                <div className="portfolio-caption-subheading text-muted">Since 2015</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* About */}

        <section className="page-section" id="about">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">About</h2>
                    <h3 className="section-subheading text-muted">This is how I got to where I'm today.</h3>
                </div>
                <ul className="timeline">
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid"
                            src="assets/su.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>1990</h4>
                                <h4 className="subheading">University of Stockholm</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">
                                Associate Degree in Computer Science.
                            </p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/ericsson.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>1990 - 1996</h4>
                                <h4 className="subheading">Ericsson Telecom AB &amp; ELLEMTEL Research.</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">
                                Development of the managment user interface for the new generation of ERICSSON broadband systems, AXE&#8209;N.
                                Technical project management and development configuration management system for use in the integration and test of AXE.
                                Development of several system-test analysis tools for the AXE telecommunication platform.
                            </p></div>
                        </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/logica.png" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>1996 - 1997</h4>
                                <h4 className="subheading">Logica Svenska AB</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">
                                Technical project management and development of a travel booking system for SMART,
                                the main provider of travel booking access in the Swedish market.
                            </p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/sun.jpeg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>1997 - 2005</h4>
                                <h4 className="subheading">Sun Microsystems</h4>
                            </div>
                            <div className="timeline-body">
                                <p className="text-muted">
                                    Last assignment as a Senior Wireless Architect for the Sun Java Content Delivery System, CDS.
                                    As a Senior Architect, I was responsible for parts of the CDS architecture and development.
                                    Worked as a Senior Java Architect consulting and helping companies with their Java developent and strategies.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/seb.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>2005 - 2011</h4>
                                <h4 className="subheading">Skandinaviska Enskilda Banken AB</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">
                                Portal Chief Architect - Strategy and development of the SEB Portal Architecture.
                                Architect/consultant catering for SEB projects that need Java expertise.
                            </p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/dalockr.png" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>2011 - 2013</h4>
                                <h4 className="subheading">daLockr &amp; pehrs.com</h4>
                            </div>
                            <div className="timeline-body">
                                <p className="text-muted">
                                    daLocker was a startup focusing on services for facilitating content identity at global scale.
                                    At pehrs.com I was self-emplyed consultant helping customers with software architecture and implementation.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/spotify.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>2013 - 2024</h4>
                                <h4 className="subheading">Spotify</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">
                                Software craftsman developing and maintaining Spotify's backend services for search.
                                Developing, maintaining and technical product owner of Spotify's
                                infrasturcure services for data collection into Hadoop.
                            </p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/seb-embedded.png" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>2024 - </h4>
                                <h4 className="subheading">SEB Embedded</h4>
                            </div>
                            <div className="timeline-body">
                                <p className="text-muted">
                                    Working as a Senior Platform Engineeer helping developers to build microservice applications.
                                </p>
                            </div>
                        </div>
                    </li>
                    {/* <li className="timeline-inverted">
                        <div className="timeline-image">
                            <h4 className="timeline-image-txt and-here-we-are">
                                And here
                                <br />
                                we
                                <br />
                                are!
                            </h4>
                        </div>
                    </li> */}
                </ul>
            </div>
        </section>

        {/* Contact/team */}

        <section className="page-section-lighter page-section" id="contact">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Contact</h2>
                    <h3 className="section-subheading text-muted">Let's chat about how I could help you!</h3>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="team-member">
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="team-member">
                            <a className="no-link-color" target="_" href="/assets/cv-matti-pehrs.pdf"><img className="mx-auto rounded-circle" src="assets/matti.jpg" alt="..." /></a>
                            <a className="text-decoration-none" target="_" href="/assets/cv-matti-pehrs.pdf"><h4>Matti Pehrs</h4></a>
                            <p className="text-muted"><a target="_" href="http://manifesto.softwarecraftsmanship.org/">Software craftsman</a></p>
                            <a title="Mattis Linkedin profile" className="btn btn-light btn-social mx-2" target="_" href="https://www.linkedin.com/in/mattipehrs/" aria-label="Matti Pehrs LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                            <a title="Mattis Github repos" className="btn btn-light btn-social mx-2" target="_" href="https://github.com/pehrs?tab=repositories" aria-label="Matti Pehrs Github Profile"><i className="fab fa-github"></i></a>
                            <a title="Mattis CV" className="btn btn-light btn-social mx-2" target="_" href="/assets/cv-matti-pehrs.pdf" aria-label="Matti Pehrs CV"><i className="fas fa-file-pdf"></i></a>
                        </div>
                        {/* <div className="team-member">
                            <span onClick={login}>LOGIN</span>
                        </div> */}
                    </div>
                    <div className="col-lg-4">
                        <div className="team-member">
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 mx-auto text-center"><p className="large text-muted">

                    </p></div>
                </div>
            </div>
        </section>

        {/* Footer */}
        <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    {/* 
                    <div className="col-lg-4 text-lg-start">Copyright &copy; Matti Pehrs<br/>2023 - 2024</div>
                    <div className="col-lg-4 my-3 my-lg-0">
                        <div style={{ textAlign: "center" }}>Built with <a target="_" href="https://react.dev/">React</a></div>
                    </div>
                    <div className="col-lg-4 text-lg-end">
                        <a className="" href="/?page=terms">Terms of Use</a>
                    </div> */}

                    <div className="copyright">Copyright &copy; Matti Pehrs<br />1990 - 2024</div>
                    <div className="react-link">
                        <div>
                            Built with&nbsp;
                            <a target="_" href="https://github.com/synesthesiam/docker-mozillatts">Mozilla TTS</a>
                            &nbsp;&amp;&nbsp;
                            <a target="_" href="https://react.dev/">React</a>
                        </div>
                    </div>
                    <div className="terms-of-use-link">
                        <a className="" href="/tos">Terms of Use</a>
                    </div>

                    {/* 
                    <span className="copyright">
                        Copyright &copy; Matti Pehrs<br />2023 - 2024<br />
                    </span>
                    <span className="react-link">
                        Built with <a target="_" href="https://react.dev/">React</a>
                    </span>
                    <span className="term-of-use-link">
                        <a className="" href="/?page=terms">Terms of Use</a>
                    </span> */}
                </div>
            </div>
        </footer>


    </div>
}
